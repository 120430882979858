import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WebSocketService } from '../services/web-socket.service';

@Injectable({ providedIn: 'root' })
export class MachineController {
  constructor(
    private httpClient: HttpClient,
    private wsService: WebSocketService
  ) {}
  private prefix = environment.machineApi + '/';

  cima = () => {
    this.mover('up');
  };

  direita = () => {
    this.mover('right');
  };

  baixo = () => {
    this.mover('down');
  };

  esquerda = () => {
    this.mover('left');
  };

  private mover = (command: string) => {
    this.wsService.sendMessage({
      command: 'message',
      identifier: '{"channel": "MachineChannel"}',
      data: '{"action": "move", "command": "' + command + '"}',
    });
  };

  private conectar = () => {
    this.wsService.sendMessage({
      command: 'subscribe',
      identifier: '{"channel": "MachineChannel"}',
    });
  };

  consultarSaida = (machineId: number) => {
    return this.httpClient.get(this.prefix + 'consultar-estoque/' + machineId, {
      headers: new HttpHeaders({
        'x-access-token': environment.token,
      }),
    });
  };

  credito = () => {
    this.conectar();
    this.wsService.sendMessage({
      command: 'message',
      identifier: '{"channel": "MachineChannel"}',
      data: '{"action": "remote_credit", "amount":"2"}',
    });
  };

  descer = () => {
    this.mover("center");
  };

  connectionWS = (externalCode: string) => {
    this.wsService.connection(externalCode);
  }
}
