import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MaquinaPartidaController } from '../../core/controllers/maquina-partida.controller';
import { ToyToastService } from '../../core/services/toast.service';
import { MachineController } from '../../core/controllers/machine.controller';
import { CoinService } from '../../core/services/coin.service';

@Component({
  selector: 'toy-modal-jogo',
  standalone: false,
  templateUrl: 'modal-jogo.component.html',
})
export class ToyModalJogoComponent implements OnInit, OnDestroy {
  constructor(
    private machineController: MachineController,
    private maquinaPartidaController: MaquinaPartidaController,
    private toyToastService: ToyToastService,
    private _router: Router,
    private coinService: CoinService
  ) {}

  private timerSubscription: any;
  timeLeft: number = 10;

  @Input() machineId!: number;
  @Input() machineMatchId!: number;
  @Input() dataMachine: any;
  @Output() onPlayAgain = new EventEmitter();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timeLeft = 10;
      clearInterval(this.timerSubscription);
    }
  }

  showModalJogo = false;

  exibirModalJogo = () => {
    this.showModalJogo = true;
    this.startTimeLeft();
  };

  redirectComprarFichas = () => {
    this.nao('perfil/deposito-saque');
  };

  clearTime = () => {
    if (this.timerSubscription) {
      this.timeLeft = 10;
      clearInterval(this.timerSubscription);
    }
  };
  nao(navigatePage: string = 'maquina'): void {
    this.clearTime();
    this.maquinaPartidaController
      .encerrarPartida(this.machineMatchId)
      .subscribe({
        next: (e: any) => {
          this.showModalJogo = false;
          this._router.navigate([navigatePage]);
        },
        error: (err: any) => {},
      });
  }

  sim = () => {
    this.clearTime();

    this.maquinaPartidaController.finalizar(this.machineMatchId).subscribe({
      next: () => {
        this.entrarNaFila();
      },
    });
  };

  entrarNaFila = () => {
    if (this.machineId !== null) {
      this.maquinaPartidaController.create(this.machineId, true).subscribe({
        next: (e) => {
          this.machineController.credito()
          // .subscribe({
          //   next: (res) => {
              this.coinService.changed();
              this.showModalJogo = false;
              this.onPlayAgain.emit();
          //   },
          // });
        },
        error: (err) => {
          this.toyToastService.error(err.message);
          this._router.navigate(['perfil/deposito-saque']);
        },
      });
    }
  };

  startTimeLeft(): void {
    this.timerSubscription = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.nao();
      }
    }, 1000);
  }
}
