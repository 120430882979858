import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket?: WebSocket;
  private message = new Subject<any>();
  message$ = this.message.asObservable();

  constructor() { }

  connection = (externalCode: string) => {
    
      this.socket = new WebSocket(
        `wss://server.bluepaypag.com/cable?accessToken=3f360143f606011deaea00144aeac490&userId=e5e732ce-aacc-4b58-b3ce-345cca2086e6&telemetryId=${externalCode}`
      );

      // conexão for aberta
      this.socket.onopen = () => {
        console.log('Conectado ao servidor');
      };

      this.socket.onmessage = (event) => {
        this.message.next(event.data);
      };

      this.socket.onerror = (error) => {
        console.log(error);
      };

      this.socket.onclose = () => {
        console.log('Fechou');
      };
    
  };

  sendMessage(command: any): void {
    this.socket?.send(JSON.stringify(command));
  }
}
